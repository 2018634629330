import deps from "../depsloader";

export default () => {
  deps.load("lightgallery").then(() => {
    const elms = document.querySelectorAll(".lightgallery");
    if (elms) {
      for (let element of elms) {
        let config = null;
        if (element.dataset.config) {
          config = element.dataset.config;
          element.removeAttribute("data-config");
        }

        lightGallery(element, JSON.parse(config)); // eslint-disable-line
      }
    }

    const exposeBtn = document.querySelector("#exGalleryBtn");
    if (exposeBtn) {
      exposeBtn.addEventListener("click", function(e) {
        e.preventDefault();
        const gallery = document.querySelector("#exGallery");
        if (gallery) {
          gallery.firstElementChild.click();
        }
      })
    }

    const frames = document.querySelectorAll(".iframepopup > a");
    if (frames) {
      for (let element of frames) {
        element.setAttribute("data-iframe", true);

        lightGallery(element, { // eslint-disable-line
            selector: 'this',
            iframe: true
        });
      }
    }
  });
}
