import depsloader from "../../../depsloader";

// sollte irgendwann mal per rest kommen
const icons = {
  "area.livingSpace": "fal fa-vector-square",
  "area.numberRooms": "fal fa-bed",
  "property[anzahl_zimmer]": "fal fa-bed",
  "area.plotArea": "fal fa-street-view",
  "area.gardenArea": "fal fa-leaf",
  "area.officeSpace": "fal fa-building",
  "area.floorSpace": "fab fa-codepen",
  "area.totalArea": "fal fa-expand",
  "property[baujahr]": "fal fa-calendar-alt",
  "property[anzahl_schlafzimmer]": "fal fa-bed",
  "property[anzahl_badezimmer]": "fal fa-shower",
  "property[anzahl_stellplaetze]": "fal fa-car-garage"
};

function buildFields(config, fieldsConfig) {
  const fields = [];
  const added = [];

  config.categoryList.forEach(category => {
    category.fieldList.forEach(e => {
      let f;
      if (typeof e == "object") {
        f = e;
      } else {
        f = {
          var: e,
          range: false
        };
      }

      if (!(f.var in fieldsConfig)) {
        return;
      }

      if (added.indexOf(f.var) > -1) {
        return;
      }

      added.push(f.var);

      let label = "";
      let marketingTypeLabel = null;
      if ("marketingTypeLabel" in fieldsConfig[f.var]) {
        marketingTypeLabel = fieldsConfig[f.var].marketingTypeLabel;
        label = Object.values(fieldsConfig[f.var].marketingTypeLabel).join("/");
      } else {
        label = fieldsConfig[f.var].label;
      }

      let icon = null;
      if (f.var in icons) {
        icon = icons[f.var];
      }

      const field = {
        id: f.var,
        unit: fieldsConfig[f.var].unit,
        values: fieldsConfig[f.var].values,
        range: f.range,
        group: fieldsConfig[f.var].group,
        icon: icon,
        label: label,
        marketingTypeLabel: marketingTypeLabel,
        type: fieldsConfig[f.var].type,

        // inquiry field structure
        ds: {
          valueType: null,
          data: null,
          value: null,
          minValue: null,
          maxValue: null,
          name: f.var
        }
      };

      if (f.range) {
        field.ds.valueType = "RANGE";
      } else if (field.type == "string" || field.type == "select") {
        field.ds.valueType = "RAW";
      } else {
        field.ds.valueType = "NUMBER";
      }

      fields.push(field);
    });
  });

  return fields;
}

export default function(config, $t) {
  return new Promise((resolve) => {
    const requestData = {};
    if (config && config.categoryList.length > 0) {
      config.categoryList.forEach((c) => {
        if ("fieldList" in c && c.fieldList.length > 0) {
          c.fieldList.forEach((f) => {
            if (typeof f == "object") {
              requestData[f.var] = {};
            } else {
              requestData[f] = {};
            }
          });
        }
      });
    }

    if (requestData) {
      depsloader.load("axios").then((axios) => {
        axios.post("/wp-json/sw-immo/v1/inquiry-fields-config", requestData).then(response => {
          const res = response.data;
          Object.keys(requestData).forEach((k) => {
            if (k in res) {
              if (res[k].unit) {
                res[k].type = "number";
              } else if (res[k].type == "bool") {
                res[k].type = "select";
                res[k].values = [
                  {
                    value: "1",
                    title: $t("fieldYes")
                  },
                  {
                    value: "0",
                    title: $t("fieldNo")
                  }
                ];
              }
              requestData[k] = res[k];
            }
          });

          resolve(buildFields(config, requestData));
        });
      });
    }
  });
}
