<template>
  <edit-text-field :label="label" :value="value" :suffix="unit" @input="update" v-if="type == 'currency'" format="currency"></edit-text-field>
  <edit-text-field :label="label" :value="value" :suffix="unit" @input="update" v-else-if="type == 'number'" format="decimal"></edit-text-field>
  <edit-text-field :label="label" :value="value" :suffix="unit" @input="update" v-else-if="type == 'decimal'" format="decimal"></edit-text-field>
  <edit-text-field :label="label" :value="value" :suffix="unit" @input="update" v-else-if="type == 'int'" format="int"></edit-text-field>
</template>

<script>
import EditTextField from "../utils/EditTextField";

export default {
  name: "InquiryFieldNumber",

  components: {
    EditTextField
  },

  props: [
    "label",
    "value",
    "type",
    "unit"
  ],

  computed: {
    suffix() {
      return this.unit;
    }
  },

  methods: {
    update(value) {
      this.$emit("update", value);
    }
  }
};
</script>
