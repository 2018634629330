<template>
<div>
  <v-row>
    <v-col cols="24" sm="12">
      <v-radio-group :value="objectType" @change="setObjectType" row>
        <v-radio :label="$t('Wohnen')" value="LIVING"></v-radio>
        <v-radio :label="$t('Gewerbe')" value="COMMERCIAL"></v-radio>
      </v-radio-group>
    </v-col>

    <v-col cols="24" sm="12" class="text-right">
      <v-radio-group v-if="inquiryType == 'lead'" :value="marketingType" @change="setMarketingType" row>
        <v-radio :label="$t('Verkaufen')" value="BUY"></v-radio>
        <v-radio :label="$t('Vermieten')" value="RENT"></v-radio>
      </v-radio-group>
      <v-radio-group v-else :value="marketingType" @change="setMarketingType" row>
        <v-radio :label="$t('Kaufen')" value="BUY"></v-radio>
        <v-radio :label="$t('Mieten')" value="RENT"></v-radio>
      </v-radio-group>
    </v-col>
  </v-row>

  <v-select
    :value="category"
    :items="categories"
    item-text="displayText"
    return-object
    :label="$t('Kategorie')"
    @change="setCategory"
    attach
    filled
    flat
  ></v-select>
</div>
</template>

<script>

export default {
  name: "InquiryCategories",

  props: [
    "category",
    "marketingType",
    "objectType",
    "categoryList",
    "inquiryType"
  ],

  computed: {
    categories() {
      const list = [];

      this.categoryList.forEach(c => {
        c.displayText = this.$t(c.text);
        if ("marketingType" in c && c.marketingType == this.marketingType) {
          list.push(c);
        } else if ("objectType" in c && c.objectType == this.objectType) {
          list.push(c);
        }
      });

      return list;
    }
  },

  methods: {
    setObjectType(val) {
      this.$emit("objectType", val);
    },

    setMarketingType(val) {
      this.$emit("marketingType", val);
    },

    setCategory(val) {
      this.$emit("category", val);
    }
  }
};
</script>
