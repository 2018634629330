<template>
<app-wrapper>
  <v-alert v-if="loginError" :value="true" type="error">{{ $t('Während der Authentifizierung ist ein Fehler aufgetreten. Bitte versuchen Sie es noch einmal.') }}</v-alert>
  <immoapp-login v-else>
    <div class="immoapp__logout" @click="logout()">
      <span class="fas fa-sign-out" title="Logout"></span>
      {{ $t("userLogout", { username: username }) }}
    </div>
    <router-view></router-view>
  </immoapp-login>
</app-wrapper>
</template>

<script>
import AppWrapper from "./AppWrapper";
import ImmoappLogin from "./ImmoappLogin";

export default {
  name: "Immoapp",

  components: {
    AppWrapper,
    ImmoappLogin
  },

  computed: {
    loginError() {
      return this.$store.state.auth.loginError;
    },

    username() {
      return this.$store.state.user.email;
    }
  },

  methods: {
    logout() {
      this.$store.dispatch("auth/logout");
    }
  }
};
</script>
