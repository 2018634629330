<template>
<v-app>
  <app-loading v-if="initialized"></app-loading>
  <slot v-if="appLoaded"></slot>
</v-app>
</template>

<script>
import AppLoading from "./AppLoading.vue";

export default {
  name: "AppWrapper",

  components: {
    AppLoading
  },

  data() {
    return {
      initialized: false
    }
  },

  computed: {
    appLoaded() {
      return this.$store.state.appLoaded;
    }
  },

  watch: {
    appLoaded() {
      window.setTimeout(() => {
        this.initialized = true;
      }, 500);
    }
  }
};
</script>

