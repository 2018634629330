<template>
<v-app>
  <v-row>
    <v-col cols="24" lg="12">
    <contact-form :name="name" :required-fields="requiredFields">
        <contact-fields :exclude="exclude"></contact-fields>
    </contact-form>
    </v-col>

    <v-col cols="24" lg="11" offset-lg="1">
      <contact-company-info></contact-company-info>
    </v-col>
  </v-row>
</v-app>
</template>

<script>
import ContactForm from "./ContactForm";
import ContactFields from "./ContactFields";
import ContactCompanyInfo from "./ContactCompanyInfo";

export default {
  name: "ContactFormApp",

  components: {
    ContactForm,
    ContactFields,
    ContactCompanyInfo
  },

  data() {
    return {
      name: "contact"
    }
  },

  computed: {
    requiredFields() {
      return this.$yeti.formRequiredFields(this.name);
    },

    exclude() {
      if (this.$root.exclude) {
        return this.$root.exclude;
      }

      return [];
    }
  }
};
</script>
