<template>
<div>
  <v-text-field :value="cvalue" :suffix="suffix" @focus="change" @blur="blur" :label="label" @input="input" filled></v-text-field>
</div>
</template>

<script>
export default {
  name: "EditTextField",

  props: [
    "format",
    "value",
    "suffix",
    "label"
  ],

  data() {
    return {
      mode: "show"
    }
  },

  computed: {
    cvalue() {
      if (this.mode == "show") {
        if (!this.value) {
          return null;
        }

        return this.$n(this.value, this.format);
      }

      return this.value;
    },

    type() {
      if (this.mode == "edit") {
        return "number";
      }

      return "text";
    }
  },

  methods: {
    input(val) {
      let n = parseFloat(val);
      if (Number.isNaN(n)) {
        n = "";
      }
      this.$emit("input", n);
    },

    change() {
      if (this.mode == "show") {
        this.mode = "edit";
      }
    },

    blur() {
      if (this.mode == "edit") {
        this.mode = "show";
      }
    }
  }
};
</script>
