<template>
<div>
  <div class="row">
    <div class="col-24 col-md-12">
      <contact-select name="salutation" label="Anrede"  group="contact" values="Frau,Herr,Familie,Firma"></contact-select>
    </div>

    <div class="col-24 col-md-12">
      <contact-input name="lastname" label="Ihr Name" group="contact"></contact-input>
    </div>

    <div class="col-24 col-md-12">
      <contact-input name="city" label="Standort" group="contact"></contact-input>

      <label class="mr-1 float-left">Ihre Bewertung:</label> <rating></rating>
    </div>

    <div class="col-24 col-md-12">
      <contact-input textarea name="message" group="contact" label="Ihre Meinung"  class="testimonials-form__textarea"></contact-input>
    </div>
  </div>
</div>
</template>

<script>
import ContactInput from "./ContactInput.vue";
import ContactSelect from "./ContactSelect.vue";
import Rating from "./TestimonialRating.vue";

export default {
  name: "TestimonialForm",

  components: {
    ContactInput,
    ContactSelect,
    Rating
  }
}
</script>
