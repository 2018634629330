import Vue from "vue";
import vuetify from "../vuetify.js";
import ContactApp from "../components/contact/ContactApp.vue";

export default function() {
  const element = document.querySelector("#vue-contact");

  if (element) {
    let vueArgs = {};
    let renderFn = ContactApp;

    if (element.dataset.name && element.dataset.name == "testimonial") {
      renderFn = require("../components/contact/TestimonialApp.vue").default;
    }

    vueArgs = {...vueArgs, ...{
      el: element,

      components: {
        ContactApp
      },

      computed: {
        objectList() {
          if (element.dataset.objectlist) {
            return JSON.parse(element.dataset.objectlist);
          }

          return null;
        }
      },

      vuetify,

      render: h => h(renderFn)
    }};

    new Vue(vueArgs)
  }
}

