<template>
<app-wrapper>
  <saved-search-wizard></saved-search-wizard>
</app-wrapper>
</template>

<script>
import AppWrapper from "../AppWrapper";
import SavedSearchWizard from "./SavedSearchWizard";

export default {
  name: "SavedSearchStandaloneApp",

  components: {
    AppWrapper,
    SavedSearchWizard
  }
};
</script>
