<template>
  <standard-map v-model="map" :value="mapCenter" :zoom="mapZoom" @initialized="onMapLoad"></standard-map>
</template>

<script>
import StandardMap from "../StandardMap";

export default {
  name: "Map",

  components: {
    StandardMap
  },

  data() {
    return {
      map: null
    }
  },

  computed: {
    mapCenter() {
      return this.$root.mapCenter;
    },

    mapZoom() {
      return this.$root.mapZoom;
    }
  },

  methods: {
    onMapLoad(o) {
      o.L.marker(this.mapCenter).addTo(o.map);
      this.map = this.mapCenter;
      o.map.scrollWheelZoom.disable();
    }
  }
}
</script>
